import { RichText } from 'prismic-reactjs';
import React, { useEffect, useState } from 'react';
import SEO from '../components/seo';
import withTranslate from '../utils/withTransWrapper';
import styled, { keyframes } from 'styled-components';
import momentTimezone from 'moment-timezone';
import Img from 'gatsby-image';
import Footer from '../../src/components/layout/footer';
import GermanSocialIcon from '../../src/images/GermanSocialLogo.svg';
import {
  AiFillFacebook,
  AiOutlineTwitter,
  AiOutlineInstagram,
  AiFillMediumSquare,
  AiFillLinkedin,
  AiFillGithub,
} from 'react-icons/ai';

import { Button } from '@material-ui/core';
import { FaLocationArrow } from 'react-icons/fa';
import { graphql } from 'gatsby';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { contactPage } from '../constants';

const Contact = ({ data }) => {
  const prismicContact = data?.prismicContact;
  const [date, setDate] = useState(Date());

  // Hamburg
  const imagesHammRaw = prismicContact.data?.body[1].items;
  const imageHam = imagesHammRaw.map((val) => val.hamburg_image);

  // CNX
  const imagesCNXRaw = prismicContact.data?.body[0].items;
  const imageCNX = imagesCNXRaw.map((val) => val.hamburg_image);
  const half_length = Math.ceil(imageCNX.length / 2);
  const topSide = imageCNX.slice(0, half_length);
  const bottomSide = imageCNX.slice(half_length, imageCNX.length);

  useEffect(() => {
    const timerID = setInterval(() => setDate(Date()), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  const prismicHamburgDetail = prismicContact?.data.body.find(
    (field) => field.slice_type === 'hamburg',
  ).primary;
  const prismicChiangMaiDetail = prismicContact?.data.body.find(
    (field) => field.slice_type === 'chiang_mai',
  ).primary;

  const settingsFader = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };

  const EmailConnector = () => {
    return (
      <div
        css={`
          display: flex;
          flex-direction: column;
        `}
      >
        <div>
          Pitch Decks bitte an <a href="mailto:pitch@20scoops.com">pitch@20scoops.com</a>
        </div>
        <div>
          Bewerbungen bitte an <a href="mailto:bewerbung@20scoops.com">bewerbung@20scoops.com</a>
        </div>
        <div>
          Andere Anfragen bitte an <a href="mailto:mail@20scoops.com">mail@20scoops.com</a>
        </div>
      </div>
    );
  };

  return (
    <>
      <SEO title={'Contact'} />
      <PageWrapper>
        <FadeStyle />
        <Container>
          <Title>{RichText.render(prismicContact?.data.contact_title.raw)}</Title>
          <ContentContainer>
            <RowOneContainer>
              <ImageColumnContainer>
                <Slider {...settingsFader} autoplaySpeed={3000}>
                  {imageHam.map((image, index) => (
                    <ImageContainerLarge key={`imageContainerLarge-${index}`}>
                      <TestImageFader fluid={image.fluid} loading="eager" />
                    </ImageContainerLarge>
                  ))}
                </Slider>
              </ImageColumnContainer>
              <TextContainer>
                <TitleCountry>Hamburg</TitleCountry>
                <TimeZone>{momentTimezone(date).tz('Europe/Berlin').format('HH:mm')}</TimeZone>
                <MailTelContainer>
                  <MailTextStyle>
                    <EmailConnector />
                  </MailTextStyle>
                  <TelNumber>
                    {RichText.render(prismicHamburgDetail.hamburg_contact_number.raw)}
                  </TelNumber>
                </MailTelContainer>
                <SocialIcon>
                  <a
                    href="https://www.facebook.com/20Scoops/"
                    rel="noreferrer"
                    target="_blank"
                    aria-label={contactPage.ariaLabel.hamburg.FACEBOOK}
                  >
                    <IconFacebook size={21} />
                  </a>
                  <a
                    href="https://twitter.com/twentyscoops"
                    rel="noreferrer"
                    target="_blank"
                    aria-label={contactPage.ariaLabel.hamburg.TWITTER}
                  >
                    <IconTwitter size={21} />
                  </a>
                  <a
                    href="https://www.instagram.com/twentyscoops_hamburg/"
                    rel="noreferrer"
                    target="_blank"
                    aria-label={contactPage.ariaLabel.hamburg.INSTAGRAM}
                  >
                    <IconInstagram size={21} />
                  </a>
                  <a
                    href="https://www.xing.com/pages/20scoopsventurecapitalgmbh-co-kg"
                    rel="noreferrer"
                    target="_blank"
                    aria-label={contactPage.ariaLabel.hamburg.XING}
                  >
                    <SpecialSocialIcon src={GermanSocialIcon} alt={contactPage.altText.XING} />
                  </a>
                </SocialIcon>
                <AddressText>
                  {RichText.render(prismicHamburgDetail.hamburg_address.raw)}
                </AddressText>
              </TextContainer>
              <ButtonLocation
                endIcon={<FaLocationArrow size={12} />}
                href="https://www.google.com/maps/place/+Rathausstra%C3%9Fe+7,+20095+Hamburg,+Germany/@53.5501489,9.9928534,17z/data=!3m1!4b1!4m5!3m4!1s0x47b18f1c577c4b91:0x79bc1c45bb69a263!8m2!3d53.5501489!4d9.9950421?hl=en-US"
              >
                Get Directions
              </ButtonLocation>
            </RowOneContainer>

            <RowTwoContainer>
              <ImageColumnContainer>
                <SliderWrapperSmall>
                  <Slider
                    {...settingsFader}
                    autoplaySpeed={2000}
                    css={`
                      overflow: hidden;
                    `}
                  >
                    {topSide.map((image, index) => (
                      <ImageContainerSmall key={`imageContainerSmall-${index}`}>
                        <TestImageFader
                          fluid={image.fluid}
                          loading="eager"
                          css={`
                            @media (min-width: 767px) and (max-width: 812px) {
                              height: 100%;
                              max-height: 230px;
                            }
                          `}
                        />
                      </ImageContainerSmall>
                    ))}
                  </Slider>
                </SliderWrapperSmall>
                <SliderWrapperSmall>
                  <Slider {...settingsFader} autoplaySpeed={2500}>
                    {bottomSide.map((image, index) => (
                      <ImageContainerSmall key={`imageContainerSmall-${index}`}>
                        <TestImageFader
                          fluid={image.fluid}
                          loading="eager"
                          css={`
                            @media (min-width: 767px) and (max-width: 812px) {
                              height: 100%;
                              max-height: 230px;
                            }
                          `}
                        />
                      </ImageContainerSmall>
                    ))}
                  </Slider>
                </SliderWrapperSmall>
              </ImageColumnContainer>
              <TextContainer>
                <TitleCountry>Chiang Mai</TitleCountry>
                <TimeZone>{momentTimezone(date).tz('Asia/Bangkok').format('HH:mm')}</TimeZone>
                <MailTelContainer>
                  <MailTextStyle href="mailto:mail@20scoops.net">
                    <a href="mailto:mail@20scoops.net">cnx@20scoops.net</a>
                  </MailTextStyle>
                  <TelNumber>
                    {RichText.render(prismicChiangMaiDetail.cnx_contact_number.raw)}
                  </TelNumber>
                </MailTelContainer>
                <SocialIcon>
                  <a
                    href="https://web.facebook.com/20scoopscnx"
                    rel="noreferrer"
                    target="_blank"
                    aria-label={contactPage.ariaLabel.chiangMai.FACEBOOK}
                  >
                    <IconFacebook size={21} />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/20scoops-cnx/"
                    rel="noreferrer"
                    target="_blank"
                    aria-label={contactPage.ariaLabel.chiangMai.LINKEDIN}
                  >
                    <IconLinkein size={21} />
                  </a>
                  <a
                    href="https://medium.com/20scoops-cnx"
                    rel="noreferrer"
                    target="_blank"
                    aria-label={contactPage.ariaLabel.chiangMai.MEDIUM}
                  >
                    <IconMedium size={21} />
                  </a>
                  <a
                    href="https://www.instagram.com/20scoops_cnx"
                    rel="noreferrer"
                    target="_blank"
                    aria-label={contactPage.ariaLabel.chiangMai.INSTAGRAM}
                  >
                    <IconInstagram size={21} />
                  </a>
                  <a
                    href="https://github.com/20scoops-CNX"
                    rel="noreferrer"
                    target="_blank"
                    aria-label={contactPage.ariaLabel.chiangMai.GITHUB}
                  >
                    <IconGithub size={21} />
                  </a>
                </SocialIcon>
                <AddressText>{RichText.render(prismicChiangMaiDetail.cnx_address.raw)}</AddressText>
              </TextContainer>
              <ButtonLocation
                endIcon={<FaLocationArrow size={12} />}
                href="https://www.google.com/maps/place/20Scoops+CNX/@18.7952302,98.9690683,17z/data=!3m1!4b1!4m5!3m4!1s0x30da3b637398a057:0x27b4cf467813babd!8m2!3d18.7952302!4d98.971257?hl=en-AU"
              >
                Get Directions
              </ButtonLocation>
            </RowTwoContainer>
          </ContentContainer>
        </Container>
      </PageWrapper>
      <Footer />
    </>
  );
};

export default withTranslate(Contact);

const TestImageFader = styled(Img)`
  height: 100%;
  max-height: 740px;
  @media (min-width: 767px) and (max-width: 812px) {
    height: 100%;
    max-height: 470px;
  }
`;

const PageWrapper = styled.div`
  padding-top: 105px;
  padding-bottom: 105px;
  background: ${({ theme }) => theme.color.uiColorBG};
  position: relative;
  overflow: hidden;
  @media (max-width: 767.9px) {
    padding-bottom: 0;
  }
`;

const Container = styled.div`
  max-width: 1210px;
  margin: auto;

  @media (min-width: 767px) and (max-width: 1220px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: 767.9px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Title = styled.div`
  padding-left: 8px;
  h1 {
    font-weight: 600;
    line-height: 84px;
  }
  @media (min-width: ${({ theme }) => theme.device.laptop}) and (orientation: portrait) {
    padding-left: 80px;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (min-width: ${({ theme }) => theme.device.laptop}) and (orientation: portrait) {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media (max-width: 767.9px) {
    flex-direction: column;
  }
`;
const RowOneContainer = styled.div`
  width: 50%;
  padding-left: 8px;
  padding-right: 15px;
  @media (max-width: 767.9px) {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const RowTwoContainer = styled.div`
  width: 50%;
  padding-left: 15px;
  padding-right: 8px;
  @media (max-width: ${({ theme }) => theme.device.laptop}) and (orientation: portrait) {
    padding-right: 0px;
  }
  @media (min-width: 767px) and (max-width: 812px) {
    width: 50%;
    padding-left: 13px;
    padding-right: 0px;
  }
  @media (max-width: 766px) {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const ImageContainerLarge = styled.div`
  height: 740px;
  width: 555px;
  @media (min-width: 767px) and (max-width: 812px) {
    height: 470px;
  }
  @media (max-width: 767.9px) {
    height: 426px;
  }
`;

const ImageContainerSmall = styled.div`
  height: 366px;
  width: 555px;
  @media (min-width: 767px) and (max-width: 812px) {
    height: 230px;
  }
  @media (max-width: 767.9px) {
    height: 210px;
    width: 320px;
  }
`;

const ImageColumnContainer = styled.div`
  height: 740px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 767px) and (max-width: 812px) {
    height: 470px;
  }
  @media (max-width: 767.9px) {
    height: 420px;
  }
`;

const SliderWrapperSmall = styled.div`
  height: 366px;
  overflow: hidden;
  @media (min-width: 767px) and (max-width: 812px) {
    height: 230px;
  }
  @media (max-width: 767.9px) {
    height: 210px;
    overflow: hidden;
  }
`;

const TextContainer = styled.div`
  margin-bottom: 30px;
`;

const TitleCountry = styled.div`
  margin-top: 46px;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
`;

const TimeZone = styled.div`
  margin-top: 10px;
  font-size: 40px;
`;

const MailTelContainer = styled.div`
  min-height: 150px;
`;
const MailTextStyle = styled.div`
  font-weight: 500;
  line-height: 24px;
  margin-top: 28px;
  color: ${({ theme }) => theme.color.textBlack};
  font-size: 16px;
  a {
    color: ${({ theme }) => theme.color.textRed};
    text-decoration: underline;
  }
`;
const TelNumber = styled.div`
  margin-top: 12px;
`;
const SocialIcon = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;
const AddressText = styled.div`
  ${({ theme }) => theme.typography.link()};
  color: #4a4a4a;
  margin-top: 36px;
  @media (max-width: 1112.9px) {
    font-size: 16px;
    font-weight: 400;
  }
  @media (max-width: 400.9px) {
    max-width: 300px;
  }
  p {
    margin-bottom: 0px;
  }
`;

const IconMedium = styled(AiFillMediumSquare)`
  color: #9b9b9b;
  margin: 8px;
`;

const IconLinkein = styled(AiFillLinkedin)`
  color: #9b9b9b;
  margin: 8px;
`;

const IconGithub = styled(AiFillGithub)`
  color: #9b9b9b;
  margin: 8px;
`;

const IconFacebook = styled(AiFillFacebook)`
  color: #9b9b9b;
  margin: 8px;
  margin-left: 0;
`;
const IconTwitter = styled(AiOutlineTwitter)`
  color: #9b9b9b;
  margin: 8px;
`;
const IconInstagram = styled(AiOutlineInstagram)`
  color: #9b9b9b;
  margin: 8px;
`;
const SpecialSocialIcon = styled.img`
  height: 28px;
  margin: 4px;
`;

const ButtonLocation = styled(Button)`
  &&.MuiButton-text {
    color: ${({ theme }) => theme.color.textRed};
  }
  &&.MuiButton-root {
    padding: 0;
    font-style: normal;
    font-weight: 500;
    .MuiButton-label {
      text-transform: none;
      @media (max-width: 767.9px) {
        margin-bottom: 100px;
      }
    }
  }
`;

const fadeInCircle = keyframes`
  0% {
    transform: scale(.3);opacity:0.3;
  }
  100% {
    transform: scale(1.5);opacity:1;
  }
`;

const FadeStyle = styled.div`
  animation-name: ${fadeInCircle};
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  position: absolute;
  width: 1940px;
  height: 1219px;
  left: 700px;
  top: -847px;
  background: radial-gradient(
    38.84% 50% at 50% 50%,
    rgba(234, 2, 0, 0.28) 0%,
    rgba(234, 2, 0, 0) 60%
  );
  @media (max-width: 1024px) {
    width: 1943px;
    height: 1350px;
    left: -50px;
    top: -847px;
  }
  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    left: -350px;
  }
`;

export const query = graphql`
  query {
    prismicContact {
      data {
        body {
          ... on PrismicContactDataBodyHamburg {
            id
            items {
              hamburg_image {
                fluid(maxWidth: 1010) {
                  src
                }
              }
            }
            primary {
              hamburg_address {
                raw
              }
              hamburg_contact_number {
                raw
              }
              hamburg_link {
                url
              }
              hamburg_time {
                raw
              }
              hamburg_title {
                raw
              }
              link_directions {
                url
              }
            }
            slice_type
          }
          ... on PrismicContactDataBodyChiangMai {
            id
            items {
              hamburg_image {
                fluid(maxWidth: 1010) {
                  src
                }
              }
            }
            primary {
              cnx_address {
                raw
              }
              cnx_contact_number {
                raw
              }
              cnx_link {
                url
              }
              cnx_time {
                raw
              }
              cnx_title {
                raw
              }
              link_directions_cnx {
                url
              }
            }
            slice_type
          }
        }
        contact_address {
          raw
        }
        contact_description {
          raw
        }
        contact_email {
          url
        }
        contact_facebook {
          url
        }
        contact_instagram {
          url
        }
        contact_twister {
          url
        }
        contact_xing {
          url
        }
        contact_title {
          raw
        }
      }
      type
    }
  }
`;
